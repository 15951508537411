import React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';

function FormForgotPassword() {
    return (
        <div className="flex h-screen">
            {/* Left Pane */}
            <div className="hidden lg:flex items-center justify-center flex-1 bg-gradient-to-br from-[#2ba5b5] to-cyan-300 blur-sm">
            </div>
            <div className="w-full bg-gray-100 lg:w-1/2 flex items-center justify-center">
                <div className="max-w-md w-full p-6">
                    <h2 className="mb-3 text-2xl font-bold text-gray-900">
                        ¿Olvidaste tu contraseña?
                    </h2>
                    <p className="text-base font-normal text-gray-500">
                        ¡No te preocupes! ¡Simplemente ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña!
                    </p>
                    <div>
                        <ForgotPasswordForm clientLoad />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormForgotPassword;
