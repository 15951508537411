import { useState } from 'react';
import { motion } from "framer-motion";

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

const Steps = () => {
    const [step, setStep] = useState(1);
    const [url_odoo, setUrlOdoo] = useState(null);

    const [SelectedActivity, setSelectedActivity] = useState(null);

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleStepActivity = (data) => {
        setSelectedActivity(data); // Función para recibir datos del Step1
    };

    return (
        <div className="bg-gradient-to-br from-[#ddfaf8]">
            <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.05 }}
            >
                <div className="py-5 sm:py-5 mt-5 mb-60">
                    <div
                        className="flex flex-col text-center md:text-left md:flex-row h-screen justify-around md:items-center"
                    >
                        <div className="items-end">
                            <h1 className="text-2xl text-center text-gray-800 mb-2">
                                Con 3 pasos empezarás a
                            </h1>
                            <h1 className="text-2xl text-center text-gray-800 font-bold mb-4">
                                organizar tu empresa
                            </h1>
                            {/* Vertical Monitor */}
                            <div className="hidden md:block items-center justify-center">
                                <ol className="relative text-gray-900 border-s border-gray-900">
                                    <li className="mb-40 ms-6">
                                        <span className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-2 ring-black ${step === 1 ? 'bg-green-200' : 'bg-gray-100'}`}>
                                            1
                                        </span>
                                        <h3 className={`items-center justify-center font-medium whitespace-nowrap ${step === 1 ? 'text-green-500' : 'text-gray-900'}`}>Selecciona tu actividad</h3>
                                    </li>
                                    <li className="mb-40 ms-6">
                                        <span className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-2 ring-black ${step === 2 ? 'bg-green-200' : 'bg-gray-100'}`}>
                                            2
                                        </span>
                                        <h3 className={`items-center justify-center font-medium whitespace-nowrap ${step === 2 ? 'text-green-500' : 'text-gray-900'}`}>Completa la información de tu empresa</h3>
                                    </li>
                                    <li className="mb-40 ms-6">
                                        <span className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-2 ring-black ${step === 3 ? 'bg-green-200' : 'bg-gray-100'}`}>
                                            3
                                        </span>
                                        <h3 className={`items-center justify-center font-medium whitespace-nowrap ${step === 3 ? 'text-green-500' : 'text-gray-900'}`}> Conoce las funciones de Obox</h3>
                                    </li>
                                </ol>
                            </div>
                            {/* Horizontal Moviles */}
                            <div className="md:hidden md:block mb-1">
                                <ol className="flex items-center justify-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm  sm:text-base sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                                    <li className="flex items-center text-gray-900">
                                        <span className={`flex items-center justify-center w-8 h-8 me-2 text-sm border border-gray-900 rounded-full shrink-0 ${step === 1 ? 'bg-green-200' : 'bg-gray-100'}`}>
                                            1
                                        </span>
                                        <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                                            <path stroke={`${step === 1 ? '#21a89f' : 'currentColor'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                                        </svg>
                                    </li>
                                    <li className="flex items-center text-gray-900">
                                        <span className={`flex items-center justify-center w-8 h-8 me-2 text-sm border border-gray-900 rounded-full shrink-0 ${step === 2 ? 'bg-green-200' : 'bg-gray-100'}`}>
                                            2
                                        </span>
                                        <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                                            <path stroke={`${step === 2 ? '#21a89f' : 'currentColor'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                                        </svg>
                                    </li>
                                    <li className="flex items-center text-gray-900">
                                        <span className={`flex items-center justify-center w-8 h-8 me-2 text-sm border border-gray-900 rounded-full shrink-0 ${step === 3 ? 'bg-green-200' : 'bg-gray-100'}`}>
                                            3
                                        </span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="md:w-full lg:w-6/12 mx-auto md:mx-0">
                            <div className="bg-white p-10 shadow-xl rounded-xl">
                                {step === 1 && <Step1 onNextStep={handleNextStep} onDataSubmit={handleStepActivity} />}
                                {step === 2 && <Step2 onNextStep={handleNextStep} onPreviousStep={handlePreviousStep} SelectedActivity={SelectedActivity} setUrlOdoo={setUrlOdoo} />}
                                {step === 3 && <Step3 url_odoo={url_odoo} />}
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div >
        </div >
    );
};

export default Steps;