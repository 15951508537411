import { motion } from "framer-motion";

export const Features2 = () => (
    <section className="lg:mb-16 w-full flex flex-col justify-center items-center bg-customDarkBg1">
        <div className="custom-shape-divider-bottom-1665696614">
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
                className="custom-bg-dark1"
            >
                <path
                    d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                    className="custom-bg-dark1"
                ></path>
            </svg>
        </div>
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
        >
            <div className="flex flex-wrap items-center 2xl:w-[1450px] xl:w-[1300px] w-11/12 mx-auto md:pl-4 xl:pr-16 xl:pl-16">
                <div className="w-1/2 lg:w-1/2 flex flex-col w-full">
                    <h1 className="flex md:justify-center mt-10 mb-8 text-4xl lg:text-9xl custom-block-big-title">
                        Las más potentes soluciones del mercado para tu negocio
                    </h1>
                </div>
                <div className="w-full lg:w-1/2 mb-12 lg:mb-0 xl:pl-8">
                    <div className="mx-auto lg:mx-auto w-11/12 sm:w-4/5 md:w-3/4 lg:w-unset">
                        <ul className="mb-6">
                            <li className="flex pb-10 mb-8 ms-6 border-b border-gray-300">
                                <div className="mr-8 text-[#21a89f]">
                                    <h1 className="text-6xl font-bold font-heading">01</h1>
                                </div>
                                <div className="max-w-xs">
                                    <h3 className="mb-2 text-lg font-bold font-heading">Obox Facturación</h3>
                                    <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside">
                                        <li>¡Todas tus facturas se aceptan! Funcionamos como OSE sin costo adicional</li>
                                        <li>Genere al toque tus facturas y boletas electrónicas ilimitadas</li>
                                        <li>Ten tu información organizada y disponible en todo momento y lugar</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="flex pb-10 mb-8 ms-6 border-b border-gray-300">
                                <div className="mr-8 text-[#21a89f]">
                                    <h1 className="text-6xl font-bold font-heading">02</h1>
                                </div>
                                <div className="max-w-xs">
                                    <h3 className="mb-2 text-lg font-bold font-heading">Obox Punto de Venta</h3>
                                    <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside">
                                        <li>Venta con códigos de barras</li>
                                        <li>Gestiona promociones</li>
                                        <li>Ideal para Tiendas, Farmacias, Minimarkets</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="flex pb-10 mb-8 ms-6 border-b border-gray-300">
                                <div className="mr-8 text-[#21a89f]">
                                    <h1 className="text-6xl font-bold">03</h1>
                                </div>
                                <div className="max-w-xs">
                                    <h3 className="mb-2 text-lg font-bold font-heading">Obox Restaurantes</h3>
                                    <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside">
                                        <li>Mesa & Comandas</li>
                                        <li>Impresoras & Pantallas en cocina</li>
                                        <li>Gestion de recetas & delivery</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="flex pb-10 mb-8 ms-6 border-b border-gray-300">
                                <div className="mr-8 text-[#21a89f]">
                                    <h1 className="text-6xl font-bold">04</h1>
                                </div>
                                <div className="max-w-xs">
                                    <h3 className="mb-2 text-lg font-bold font-heading">Obox Contabilidad</h3>
                                    <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside">
                                        <li>Libros Electronicas PLE</li>
                                        <li>Inventario Permanente Valorizada</li>
                                        <li>Asientos contables automáticos</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </motion.div>
        <div className="custom-shape-divider-top-1665696661 w-full">
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
                className="custom-bg-dark2"
            >
                <path
                    d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                    className="custom-bg-dark1"
                ></path>
            </svg>
        </div>
    </section>
);
