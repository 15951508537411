import { create } from 'zustand';
import { persist } from 'zustand/middleware';


export const usePricingStore = create(((set) => {
    const storedSelectedCard = JSON.parse(localStorage.getItem('selectedCard'));
    const storedFreeTrialData = JSON.parse(localStorage.getItem('freeTrialData'));
    return {
        isMonthly: true, // Valor predeterminado para isMonthly
        setIsMonthly: (value) => set({ isMonthly: value }),
        selectedCard: storedSelectedCard || null,
        setSelectedCard: (value) => {
            set({ selectedCard: value });
            // Guardar el valor en localStorage
            localStorage.setItem('selectedCard', JSON.stringify(value));
        },
        freeTrialData: storedFreeTrialData || null,
        setFreeTrialData: (value) => {
            set({ freeTrialData: value });
            // Guardar el valor en localStorage
            localStorage.setItem('freeTrialData', JSON.stringify(value));
        },
        pricingData: [
            {
                type: 'emprendedor',
                name: "Emprendedor",
                pay: (isMonthly) => (isMonthly ? 49 : 529),
                selectPay: (isMonthly) => (isMonthly ? "mes" : "año"),
                income: "S/20.000",
                users: 2
            },
            {
                type: 'pyme',
                name: "PYME",
                pay: (isMonthly) => (isMonthly ? 89 : 961),
                selectPay: (isMonthly) => (isMonthly ? "mes" : "año"),
                income: "S/80.000",
                users: 3
            },
            {
                type: 'pro',
                name: "Pro",
                pay: (isMonthly) => (isMonthly ? 149 : 1609),
                selectPay: (isMonthly) => (isMonthly ? "mes" : "año"),
                income: "S/150.000",
                users: 3
            },
            {
                type: 'plus',
                name: "Plus",
                pay: (isMonthly) => (isMonthly ? 259 : 2797),
                selectPay: (isMonthly) => (isMonthly ? "mes" : "año"),
                income: "S/500.000",
                users: 8
            },
        ],
        pricingSelect: [
            { type: 'sales_point', price: 20.00, description: "Agregar Punto de Venta por S/20.00" },
            { type: 'sales_point_restaurant', price: 30.00, description: "Agregar Punto de Venta para Restaurantes por S/30.00" },
            { type: 'accounting', price: 50.00, description: "Agregar Contabilidad por S/50.00" },
            { type: 'additional_user', price: 25.00, description: "Agregar Usuario por S/25.00" }
        ],
    };
}))


export const useRegisterStore = create(
    persist(
        (set) => ({
            formDataRegister: {
                email: '',
                password: '',
                googleAuth: false,
            },
            setFormDataRegister: (formDataRegister) =>
                set((state) => ({
                    ...state,
                    formDataRegister,
                })),
        }),
        {
            name: 'register-storage',
            getStorage: () => sessionStorage,
        }
    )
);


export const useUrlstore = create(persist(
    (set) => ({
        url_obox: null,
        setUrlObox: (urlObox) => set({ url_obox: urlObox }),
    }),
    {
        name: 'urlstore',
        getStorage: () => sessionStorage,
    }
));
