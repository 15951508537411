import { fetchData } from "../../../lib/data";

function ResetPasswordForm({ token, email }) {
    console.log(token, email);

    const handleSubmit = (event) => {
        event.preventDefault();
        const password = event.target.password.value;
        const confirmPassword = event.target['confirm-password'].value;

        if (password !== confirmPassword) {
            alert("Las contraseñas no coinciden");
            return;
        }

        fetchData('api/users/reset_password/', 'POST', { 'Content-Type': 'application/json' }, { token, email, password }, true)
            .then((data) => {
                console.log(data);
                // redireccionar al login
                window.location.href = 'auth/login';
            })
            .catch((error) => {
                console.error(error);
                alert(`Error al reestablecer contraseña: ${error.message}`);
            });
    }

    return (
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Nueva contraseña
                </label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                    required
                />
            </div>
            <div>
                <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
                    Confirmar Nueva contraseña
                </label>
                <input
                    type="password"
                    name="confirm-password"
                    id="confirm-password"
                    placeholder="••••••••"
                    className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                    required
                />
            </div>
            <button type="submit" className="w-full bg-[#21a89f] text-white p-2 rounded-md hover:bg-gray-800 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors duration-300">
                Reestablecer contraseña
            </button>
        </form>
    )
}

export default ResetPasswordForm;
