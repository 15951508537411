// format date to YYYY-MM-DD
export const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	let month = `${date.getMonth() + 1}`;
	let day = `${date.getDate()}`;
	const year = date.getFullYear();
	let hours = `${date.getHours()}`;
	let minutes = `${date.getMinutes()}`;
	let seconds = `${date.getSeconds()}`;

	if (month.length < 2) month = `0${month}`;
	if (day.length < 2) day = `0${day}`;
	if (hours.length < 2) hours = `0${hours}`;
	if (minutes.length < 2) minutes = `0${minutes}`;
	if (seconds.length < 2) seconds = `0${seconds}`;

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateWithDefaultTime = (date: string) => {
	if (!date) {
		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth() + 1;
		const day = now.getDate();
		const hours = now.getHours();
		const minutes = now.getMinutes();
		const seconds = now.getSeconds();
		const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
		const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
			.toString()
			.padStart(2, '0')}`;
		return `${formattedDate} ${formattedTime}`;
	}
	return `${formatDate(date)}`;
};

export const getParamsForUrl = (url: URL, param_names: string[]) => {
	const params: any = {};
	for (const param of param_names) {
		const value = url.searchParams.get(param);
		if (value) {
			params[param] = value;
		}
	}
	return params;
};

export const createFormData = (data: any, fields: (string | [string, string, any])[]) => {
	const formData: any = {};
	fields.forEach((field) => {
		let fieldName;
		let fieldPath;
		let defaultValue = '';

		if (Array.isArray(field)) {
			fieldName = field[0];
			fieldPath = field[1].split('.');
			if (field.length > 2) {
				defaultValue = field[2];
			}
		} else {
			fieldName = field;
			fieldPath = field.split('.');
		}

		let fieldValue = data;

		for (const path of fieldPath) {
			if (fieldValue === null || fieldValue === undefined) break;
			fieldValue = fieldValue[path];
		}

		formData[fieldName] = fieldValue === undefined || fieldValue === null ? defaultValue : fieldValue;
	});
	return formData;
};

export const createFormDataList = (dataList: any[], fields: string[]) => {
	const createSingleFormData = (data: any) => {
		const formData: any = {};
		fields.forEach((field) => {
			let fieldName;
			let fieldPath;

			if (Array.isArray(field)) {
				fieldName = field[0];
				fieldPath = field[1].split('.');
			} else {
				fieldName = field;
				fieldPath = field.split('.');
			}

			let fieldValue = data;

			for (const path of fieldPath) {
				if (fieldValue === null || fieldValue === undefined) break;
				fieldValue = fieldValue[path];
			}

			formData[fieldName] = fieldValue === undefined || fieldValue === null ? '' : fieldValue;
		});
		return formData;
	};

	return dataList.map(createSingleFormData);
};

export const getRandomId = () =>
	Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

type FormElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export const handleChangeForm = (
	e: React.ChangeEvent<FormElement>,
	setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>
) => {
	const { target } = e;
	let value: any;
	const { type } = target;

	if (target instanceof HTMLInputElement && target.type === 'checkbox') {
		value = target.checked;
	} else {
		value = target.value;
		if (type === 'number') {
			value = parseFloat(value);
		}
	}
	const name: string = target.name || getRandomId();

	setFormData((prevState) => ({
		...prevState,
		[name]: value
	}));
};

export const getCookiesFromClient = (listCookies: string[] = []) => {
	const cookies: any = {};
	const cookieList = listCookies;
	document.cookie.split(';').forEach((cookie) => {
		const [name, value] = cookie.split('=') as [string, string];
		if (cookieList.includes(name.trim())) {
			cookies[name.trim()] = value.trim();
		}
	});
	return cookies;
};

export const isEmpty = (obj: any) => Object.keys(obj).length === 0;
