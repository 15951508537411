import type { FC } from "react";

interface LoadingIndicatorProps {
  message?: string;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  message = "Cargando...",
}) => (
  <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-50 bg-opacity-50 dark:bg-opacity-50">
    <div className="px-3 py-1 text-sm font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">
      {message}
    </div>
  </div>
);

export default LoadingIndicator;
