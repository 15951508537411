import { useState } from "react";
import { fetchData, url } from "../../../lib/data";
import InputForm from "../../../components/InputForm.tsx";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Notification from '../../../components/Notification.tsx';
import ErrorNotification from '../../../components/ErrorNotification.tsx';

function ForgotPasswordForm() {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value;

        setLoading(true);
        fetchData('api/users/forgot_password/', 'POST', { 'Content-Type': 'application/json' }, { email }, true)
            .then((data) => {
                console.log(data);
                setNotification("Se ha enviado un correo electrónico con las instrucciones para reestablecer tu contraseña");
            })
            .catch((error) => {
                console.error("Mensaje de error", error);
                setError(`Error al enviar correo: ${error.error}`);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validateEmail = (name) => {
        if (!name) {
            return 'El correo es requerido';
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(name)) {
            return 'El correo no es válido';
        }
        return null;
    }

    return (
        <>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <InputForm
                    label="Tu correo electrónico"
                    name="email"
                    type="email"
                    className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                    value={email}
                    placeholder="name@company.com"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    validationFn={validateEmail}
                />

                <button type="submit" className="w-full bg-[#21a89f] text-white p-2 rounded-md hover:bg-gray-800 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors duration-300">
                    Recuperar contraseña
                </button>
                <div className="mt-4 text-sm text-gray-600 text-left">
                    <a href={url("auth/login")} class="text-[#21a89f] hover:underline">Regresar al inicio de sesión </a>
                </div>
            </form >

            {loading && <LoadingIndicator message="Enviando Correo" />
            }
            {error && <ErrorNotification message={error} onClose={setError} />}
            {notification && <Notification onClose={setNotification}>{notification}</Notification>}
        </>
    )
}

export default ForgotPasswordForm;
