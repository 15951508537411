import { useState } from 'react';
import Notification from '../../../components/Notification.tsx';
import ErrorNotification from '../../../components/ErrorNotification.tsx';

const FormRegister = ({ formDataRegister, setFormDataRegister }) => {
    // const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState(null);
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDataRegister({
            ...formDataRegister,
            [name]: value
        });
    };

    // Función para manejar el envío del formulario
    const handleSubmit = (e) => {
        e.preventDefault();

        if (formDataRegister.email && formDataRegister.password) {
            // Verificar el patrón de la contraseña
            const passwordPattern = /^.{8,}$/;
            if (!passwordPattern.test(formDataRegister.password)) {
                setError("La contraseña debe contener al menos 8 caracteres");
                return; // Detener el envío del formulario si la contraseña no cumple el patrón
            }
            window.location.href = '/auth/steps';
        } else {
            setError("Complete los datos")
        }
    };

    return (
        <div id="register">
            {notification && (
                <Notification onClose={() => setNotification(null)}>{notification}</Notification>
            )}
            {error && <ErrorNotification message={error} onClose={setError} />}
            {/* <!-- Right Pane --> */}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Correo"
                        className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                        required
                        value={formDataRegister.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='relative'>
                    <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Contraseña (min. 8 caracteres)"
                        className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
                        minLength="8"
                        required
                        value={formDataRegister.password}
                        onChange={handleInputChange}
                    />
                    <button
                        type="button"
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                        onClick={toggleShowPassword}
                    >
                        {showPassword ? (
                            // Icono para ocultar la contraseña
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-5 w-5 text-gray-700 dark:text-gray-400"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        ) : (
                            // Icono para mostrar la contraseña
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-5 w-5 text-gray-700 dark:text-gray-400"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M2 12s4.58-8 10-8 10 8 10 8-4.58 8-10 8-10-8-10-8z"
                                />
                            </svg>
                        )}
                    </button>
                </div>
                <div>
                    <button
                        type="submit"
                        className="w-full bg-[#21a89f] text-white p-2 rounded-md hover:bg-gray-800 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors duration-300"
                    > Crear Cuenta
                    </button>
                </div>
            </form>
        </div >
    );
};

export default FormRegister;