import { useState } from 'react';
import { fetchData } from '../lib/data';
import type { LoginResponse } from '../types/entities';

export const useAuth = () => {
	const [loginData, setLoginData] = useState<any>({
		email: '',
		password: '',
		google: false
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [notification, setNotification] = useState<null | string>(null);

	const login = async () => {
		setLoading(true);
		try {
			const loginResponse = await fetchData<LoginResponse>(
				'/api/users/login/',
				'POST',
				{ 'Content-Type': 'application/json' },
				loginData,
				true,
				false,
				false
			);
			//codigo a eliminar
			// document.cookie = `jwt_access=${loginResponse.token}; path=/; SameSite=None; Secure`;
			// document.cookie = `jwt_refresh=${loginResponse.refresh}; path=/; SameSite=None; Secure`;
			// ===============
			window.location.href = loginResponse.url_obox;
		} catch (err: any) {
			setError(err.detail || err.message || err.error || 'Ocurrió un error al iniciar sesión');
		} finally {
			setLoading(false);
		}
	};

	return {
		login,
		setLoginData,
		loginData,
		loading,
		error,
		notification,
		setError,
		setNotification
	};
};
