import React from 'react';

// Importa las fuentes de Inter
import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

function Layout({ title, children }) {
    return (
        <div lang="en">
            <div>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width" />
                {/* Agrega un favicon si es necesario */}
                {/* <link rel="icon" type="image/svg+xml" href="/favicon.svg" /> */}
                {/* Agrega el generador de meta si es necesario */}
                {/* <meta name="generator" content={Astro.generator} /> */}
                {/* Agrega una descripción si es necesario */}
                {/* <meta name="description" content="Descripción de tu sitio" /> */}
                <title>{title}</title>
            </div>
            <div>
                {children}
                <style>{`
          html {
            font-family: 'Inter', sans-serif;
            background-color: #ffffff;
            overflow-x: hidden;
            scroll-behavior: smooth;
          }
        `}</style>
            </div>
        </div>
    );
}

export default Layout;
