import { useEffect, } from "react";
import { motion } from "framer-motion";
import { usePricingStore } from "../../store/useStore";


const Pricing = () => {
    const { selectedCard, setSelectedCard, freeTrialData, setFreeTrialData, pricingSelect, pricingData, isMonthly, setIsMonthly } = usePricingStore();

    const handleChange = () => {
        setIsMonthly(!isMonthly);
    };

    const handleCardSelect = (card) => {
        if (selectedCard === card) {
            setSelectedCard(null);
        } else {
            setSelectedCard(card);
        }
    };

    const handleFreeTrial = (plan) => {
        // setFreeTrialData(plan);
        setFreeTrialData({
            ...plan,
            pay: plan.pay(isMonthly), // Agregar la propiedad pay
            selectPay: plan.selectPay(isMonthly) // Agregar la propiedad selectPay
        });
        window.location.href = '/auth/register'; // Navegar a la vista "register"
    };

    useEffect(() => {
        // Limpiar los datos de localStorage
        localStorage.removeItem('selectedCard');
        localStorage.removeItem('freeTrialData');
    }, []);

    return (
        <section className="w-screen flex justify-center bg-customDarkBg2 relative">
            <div className="absolute -top-16" id="pricing" />
            <div className="pb-20 pt-12 bg-customDarkBg2  2xl:w-[1150px] lg:w-[1050px]  md:w-4/5 ">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <div className="container mx-auto px-4">
                        <div className="max-w-2xl mx-auto text-center mb-16">
                            <span className="custom-block-subtitle">
                                Encuentra tu ajuste perfecto
                            </span>
                            <h2 className="mt-6 mb-6 text-4xl lg:text-5xl font-bold font-heading">
                                Elige tu mejor plan
                            </h2>
                            <p className="mb-6 text-customGrayText">
                                Seleccione el plan que se adapte a sus necesidades.
                            </p>
                        </div>
                        <div className="flex justify-center items-center text-center mb-16">
                            <label className="bg-customDarkBg3 relative flex justify-between items-center group text-sm w-90 h-12 rounded-lg pr-36 pl-1 cursor-pointer">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={!isMonthly}
                                    onChange={handleChange}
                                />
                                <span className="h-7 w-[9.5rem] flex items-center pr-2 bg-customDarkBg3 after:rounded-lg duration-300 ease-in-out after:w-[60rem] after:h-10 after:bg-green-200 after:shadow-md after:duration-300 peer-checked:after:translate-x-[9.5rem] cursor-pointer">
                                </span>
                                <div className="flex absolute text-sm font-bold">
                                    <div className={isMonthly ? "mr-9 ml-3" : "mr-9 ml-3 text-gray-400"}>
                                        Paga mensualmente
                                    </div>
                                    <div className={!isMonthly ? "text-gray-900" : "text-gray-400"}>
                                        Paga anualmente <span className="mr-2 rounded text-xs bg-gray-900 text-green-300">10% OFF</span>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="flex justify-center items-stretch">
                            <div className="gap-2 grid grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 xl:grid-cols-4">
                                {pricingData.map((plan, index) => (
                                    <div key={index} className="flex w-full col-span-3 lg:col-span-1 md:col-span-1 mx-auto sm:col-span-1 xl:col-span-1">
                                        <div className="p-8 bg-customDarkBg3 rounded-3xl">
                                            <h4 className="mb-2 text-xl font-bold font-heading text-left">
                                                {plan.name}
                                            </h4>
                                            <div className="flex justify-start items-end">
                                                <div className="text-[#21a89f] text-4xl sm:text-4xl font-bold text-left mt-4 mr-2">
                                                    S/ {plan.pay(isMonthly)}
                                                </div>
                                                <div className="text-gray-500">
                                                    / {plan.selectPay(isMonthly)}
                                                </div>
                                            </div>
                                            <ul className="mt-10 mb-2 2xl:mb-10 2xl:mb-6">
                                                <li className="mb-4 flex">
                                                    <span>Incluye <span className="font-bold">SIRE</span></span>
                                                </li>
                                                <li className="mb-4 flex">
                                                    <span>Facturas y Boletas Electrónicas <span className="font-bold">ilimitadas</span></span>
                                                </li>
                                                <li className="mb-4 flex">
                                                    <span>Ingresos hasta <span className="font-bold">{plan.income}</span> al mes</span>
                                                </li>
                                                <li className="mb-4 flex">
                                                    <span> <span className="font-bold">{plan.users} usuarios</span> con acceso</span>
                                                </li>
                                                <li className="mb-4 flex">
                                                    <span>Soporte <span className="font-bold">24/7 gratis</span></span>
                                                </li>
                                            </ul>
                                            <button
                                                className={`inline-block py-2 px-4 w-full text-base font-medium text-center rounded-lg border border-gray-300 focus:ring-4 focus:ring-gray-100 ${freeTrialData === plan ? 'bg-[#26a7aa] text-gray-50' : 'hover:bg-[#26a7aa] hover:text-gray-50'}`}
                                                onClick={() => handleFreeTrial(plan)}
                                            >
                                                Prueba Gratis
                                            </button>
                                            {/* <div>
                                                <span class="absolute right-0 top-7 z-[-1]">
                                                    <img src={PrizeGreen} />
                                                </span>
                                                <span class="absolute right-4 top-4 z-[-1]">
                                                    <img src={PrizeGreen} />
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="w-full justify-center items-center text-center">
                            <div className="mt-4 grid grid-cols-1 px-4 sm:px-3 md:px-2 sm:grid-cols-2 lg:grid-cols-4 gap-5">
                                {pricingSelect.map((card, index) => (
                                    <div key={index} className="w-full h-full">
                                        <button
                                            className={`rounded-[20px] border h-full p-10 shadow-2 hover:shadow-lg md:px-1 xl:px-10 ${selectedCard === card ? 'bg-[#21a89f] text-gray-50' : 'bg-white'}`}
                                            onClick={() => handleCardSelect(card)}
                                        >
                                            <h4 className="mb-[14px] text-xl font-bold">{card.description}</h4>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </motion.div >
            </div >
        </section >
    );
};

export default Pricing;