import { useState } from 'react';
import Notification from '../../components/Notification.tsx';
import { useUrlstore } from '../../store/useStore';

const Step3 = () => {
    const [notification, setNotification] = useState(null);
    const { url_obox } = useUrlstore();
    const url_odoo = url_obox?.url_obox;

    return (
        <div>
            {notification && (
                <Notification onClose={() => setNotification(null)}>{notification}</Notification>
            )}
            <div>
                <h5 className="text-xl text-center font-bold text-gray-700 mb-5">
                    Bienvenido a Obox
                </h5>
                <p className="w-full text-center mx-auto md:mx-2 text-gray-500 mb-5">
                    Descubre en menos de 3 minutos cómo gestionar la información de tu negocio para ganar tiempo y tranquilidad! ¡Dale Play!
                </p>
                <video className="w-full h-50 m-2 rounded-lg" controls>
                    <source src="/docs/videos/flowbite.mp4" type="video/mp4" />
                    Su navegador no soporta la etiqueta de vídeo.
                </video>
                <div className="grid place-items-end">
                    <a href={url_odoo} className="focus:outline-none text-white bg-[#21a89f] hover:bg-[#21a89f] focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-10 py-2.5 me-2 mb-2">
                        Ir a Obox
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Step3;