import React, { useState } from 'react';
import Notification from '../../components/Notification.tsx';
import ErrorNotification from '../../components/ErrorNotification.tsx';
import Spinnerloading from "../../components/SpinnerLoading.jsx";
import { fetchData } from '../../lib/data';
import { usePricingStore, useRegisterStore, useUrlstore } from '../../store/useStore';

const Step2 = ({ onNextStep, onPreviousStep, SelectedActivity }) => {

    const { formDataRegister } = useRegisterStore();
    const { freeTrialData, selectedCard } = usePricingStore();
    const setUrlStore = useUrlstore(state => state.setUrlObox);

    const [formData, setFormData] = useState({
        email: formDataRegister.email,
        password: formDataRegister.password || null,
        plan_name: freeTrialData.type,  // Tipo
        plan_pay: freeTrialData.pay,  // Precio
        plan_select: freeTrialData.selectPay,  // mes o año
        plan_users: freeTrialData.users, // usuarios
        optional_description: selectedCard?.type,  // card optional texto o null
        optional_price: selectedCard?.price, // card optional precio o null
        selection_activity: SelectedActivity || null, //Seleccion de actividad o null
        company_document_number: '',  // Ruc
        company_name: '',  // Razon Social
        company_address: '', // Dirección Fiscal
        google: formDataRegister.googleAuth, // Google Authentication
    });

    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState(null);
    const [error, setError] = useState(null);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // Validar si el valor tiene solo dígitos y limitar a 11 caracteres
        const cleanedValue = value.replace(/\D/g, '').slice(0, 11);
        setFormData((prevData) => ({ ...prevData, [name]: cleanedValue }));
        setIsButtonDisabled(cleanedValue.length !== 11);
    };

    const consultRuc = async (e) => {
        e.preventDefault();
        const ruc = formData.company_document_number;
        // Verifica si el RUC tiene 11 dígitos antes de continuar
        if (ruc.length !== 11) {
            setError("Error, el RUC debe tener 11 dígitos para poder consultar a Sunat");
            return;
        }
        const formUrl = '/api/consult_ruc/';
        const formMethod = 'POST';
        try {
            const data = await fetchData(formUrl, formMethod, { 'Content-Type': 'application/json' }, { ruc }, true);
            setNotification("Se detecto correctamente la empresa");
            // Asignar valores a formData si existen en data
            if (data.name) {
                setFormData(prevState => ({ ...prevState, company_name: data.name }));
            }
            if (data.street) {
                setFormData(prevState => ({ ...prevState, company_address: data.street }));
            }
        } catch (error) {
            console.log("Error", error)
            setError("No se ah encontrado una empresa relaciona con el Ruc ingresado.");
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("DATA Ruc", formData)
        console.log("DATA COMPLETE", formData);
        setLoading(true);
        fetchData('/api/users/register/', 'POST', { 'Content-Type': 'application/json' }, formData, true)
            .then((data) => {
                console.log("data fetch", data);
                setNotification(data?.detail || "Se registro su cuenta y compañia correctamente");
                setUrlStore({ url_obox: data.data.url_obox });
                setTimeout(() => {
                    onNextStep();
                }, 3000);
            })
            .catch((error) => {
                console.log("Error", error)
                console.log("Error.Password", error.password);
                setError("Error al registrar tu compañia");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            {loading && <Spinnerloading />}
            {notification && (
                <Notification onClose={() => setNotification(null)}>{notification}</Notification>
            )}
            {error && <ErrorNotification message={error} onClose={setError} />}
            <h5 className="text-xl text-center font-bold text-gray-700 mb-5">
                Información de tu empresa
            </h5>
            <div className="flex items-center w-full my-5">
                <input
                    type="text"
                    required
                    id="ruc"
                    name="company_document_number"
                    placeholder="RUC de la Empresa"
                    className="w-full appearance-none border-2 border-gray-300 rounded-lg px-4 py-3 placeholder-gray-400 focus:outline-none 
                    focus:border-2 focus:ring-green-600 focus:border-green-600 block focus:shadow-lg"
                    onChange={handleInputChange}
                    value={formData.company_document_number}
                />
                <button
                    onClick={(e) => consultRuc(e)}
                    type="button"
                    className={`text-white font-medium rounded-lg text-sm mx-2 px-4 py-1 text-center ${isButtonDisabled ? 'bg-blue-400 dark:bg-blue-500 cursor-not-allowed' : 'bg-blue-700 hover:bg-blue-800'}`}
                    disabled={isButtonDisabled}
                >
                    Consultar a Sunat
                </button>
            </div>
            <div className="flex flex-col w-full my-5">
                <input
                    type="text"
                    required
                    id="razonSocial"
                    placeholder="Razón Social"
                    className="appearance-none border-2 border-gray-300 rounded-lg px-4 py-3 placeholder-gray-400 focus:outline-none focus:border-2 focus:ring-green-600 focus:border-green-600 focus:shadow-lg"
                    onChange={handleInputChange}
                    value={formData.company_name}
                    pattern='^[A-Za-z0-9\s\-_.]+$'
                    disabled readOnly // Bloquea la edición del campo
                />
            </div>
            <div className="flex flex-col w-full my-5">
                <input
                    type="text"
                    required
                    id="direccionFiscal"
                    placeholder="Dirección Fiscal"
                    className="appearance-none border-2 border-gray-300 rounded-lg px-4 py-3 placeholder-gray-400 focus:outline-none focus:border-2 focus:ring-green-600 focus:border-green-600 focus:shadow-lg"
                    onChange={handleInputChange}
                    value={formData.company_address}
                    disabled readOnly // Bloquea la edición del campo
                />
            </div>
            <div className="flex justify-between">
                <button type="button" onClick={onPreviousStep} className="focus:outline-none text-white bg-[#21a89f] hover:bg-[#21a89f] focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-10 py-2.5 me-2 mb-2">
                    Retroceder
                </button>
                <button type="button" onClick={handleSubmit} className="focus:outline-none text-white bg-[#21a89f] hover:bg-[#21a89f] focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-10 py-2.5 me-2 mb-2">
                    Siguiente
                </button>
            </div>
        </div>
    );
};

export default Step2;
