import { useState, useEffect } from 'react';
import FormLogin from './FormLogin';
// import { getParamsForUrl } from '../../../lib/utils';
import { url } from '../../../lib/data';
import { useAuth } from "../../../hooks/useAuth";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ErrorNotification from "../../../components/ErrorNotification";
import Notification from "../../../components/Notification";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";


const LoginAuth = () => {
    const {
        login,
        loginData,
        loading,
        error,
        setError,
        notification,
        setNotification,
    } = useAuth();

    const [message] = useState(null);
    const [activeUser] = useState(false);

    useEffect(() => {
        if (message) {
            setNotification(message);
        }
    }, [message, setNotification]);

    const Authlogin = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                const res = await axios.get(
                    "https://www.googleapis.com/oauth2/v3/userinfo",
                    {
                        headers: {
                            Authorization: `Bearer ${response.access_token}`,
                        },
                    }
                );
                // Función para manejar la lógica de actualización de datos de inicio de sesión
                loginData.email = res.data.email;
                loginData.password = "";
                loginData.google = true;
                // Espera un momento para que los datos de inicio de sesión se actualicen antes de llamar a login
                setTimeout(() => {
                    login();
                }, 1000);
            } catch (err) {
                console.log(err);
            }
        }
    })

    return (
        <div>
            <div className="flex h-screen" id="login">
                {/* Left Pane */}
                <div className="hidden lg:flex items-center justify-center flex-1 bg-gradient-to-br from-[#2ba5b5] to-cyan-300 blur-sm">
                </div>
                <div className="w-full bg-gray-100 lg:w-1/2 flex items-center justify-center">
                    <div className="max-w-md w-full p-6">
                        <h1 className="text-3xl font-semibold mb-6 text-black text-center">Ingresa a tu cuenta</h1>
                        <h1 className="text-xl font-semibold mb-6 text-gray-500 text-center">Sigue ganando tiempo y tranquilidad</h1>
                        <div>
                            <FormLogin active_user={activeUser} message={message} />
                        </div>
                        <div className="mt-4 flex flex-col lg:flex-row items-center justify-between">
                            <div className="w-full mb-2 lg:mb-0">
                                <button onClick={() => Authlogin()} className="w-full flex justify-center items-center gap-2 bg-white text-sm text-gray-600 p-2 rounded-md hover:bg-gray-50 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition-colors duration-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4" id="google">
                                        <path fill="#fbbb00" d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"></path>
                                        <path fill="#518ef8" d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"></path>
                                        <path fill="#28b446" d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"></path>
                                        <path fill="#f14336" d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"></path>
                                    </svg> Inicia sesión con Google
                                </button>
                            </div>
                        </div>
                        <div className="mt-4 text-sm text-gray-600 text-center">
                            <p>¿No tienes una cuenta? <a href={url("auth/register")} className="text-[#21a89f] hover:underline">Registrate</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <LoadingIndicator />}
            {error && <ErrorNotification message={error} onClose={setError} />}
            {notification && (
                <Notification onClose={setNotification}>{notification}</Notification>
            )}
        </div>
    );
};

export default LoginAuth;

