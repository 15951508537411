import { motion } from "framer-motion";
import { url } from "../../lib/data";
import imageHero from "../../assets/images/heroImage.png";

export const Hero = () => {
    return (
        <section className="flex h-screen py-32 lg:py-0 bg-white" id="home">
            <div className="absolute w-full lg:w-1/2 inset-y-0 lg:right-0 hidden lg:block">
                <span className="absolute -left-6 md:left-4 top-24 lg:top-28 w-24 h-24 rotate-90 skew-x-12 rounded-3xl bg-green-400 blur-xl opacity-60 lg:opacity-95 lg:block hidden"></span>
            </div>
            <span className="w-4/12 lg:w-2/12 aspect-square bg-gradient-to-tr from-blue-600 to-green-400 absolute -top-5 lg:left-0 rounded-full skew-y-12 blur-2xl opacity-40 skew-x-12 rotate-90"></span>
            <div className="relative flex flex-col items-center text-center lg:text-left lg:py-7 xl:py-8 lg:items-start lg:max-w-none max-w-3xl mx-auto lg:mx-0 lg:flex-1 lg:w-1/2 justify-center p-8">
                <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.05 }}
                >
                    <h1 className="text-4xl leading-tight sm:text-5xl md:text-6xl xl:text-7xl font-bold text-gray-900">
                        Crece con el
                    </h1>
                    <h1 className="text-4xl leading-tight sm:text-5xl md:text-6xl xl:text-7xl font-bold text-gray-900">
                        <span className="text-transparent bg-clip-text bg-gradient-to-br from-green-500 from-20% via-green-400 via-30% to-blue-600">
                            Sistema de Facturación
                        </span>
                    </h1>
                    <h1 className="text-4xl leading-tight sm:text-5xl md:text-6xl xl:text-7xl font-bold text-gray-900">
                        más facil de usar.
                    </h1>
                    <p className="mt-8 text-gray-700">
                        Administra, vende y crea facturas ilimitidas con
                        Obox. Superpoderes para tu pyme
                    </p>
                    <div className="mt-8">
                        <a
                            href={url("auth/register")}
                            className="focus:outline-none text-white bg-[#21a89f] hover:bg-gray-900 focus:ring-4 focus:ring-green-300  font-medium rounded-lg text-lg px-5 py-3.5 me-2 mb-2"
                        >Empieza Gratis
                        </a>
                    </div>
                </motion.div>
            </div>
            <div className="hidden lg:block lg:w-1/2">
                <img src={imageHero} alt="" className="w-full h-full" />
            </div>
        </section>
    );
};
