import { useState } from "react";
import { formatDateWithDefaultTime } from "../lib/utils";

interface InputFormProps extends React.HTMLAttributes<HTMLInputElement> {
  class_style?: string;
  label?: string;
  id?: string;
  name?: string;
  type?:
    | "text"
    | "email"
    | "password"
    | "number"
    | "date"
    | "time"
    | "datetime-local"
    | "month"
    | "week"
    | "tel"
    | "url"
    | "search"
    | "color";
  value?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validationFn?: (value: string) => string | null | undefined;
  // setError?: (error: string | null | undefined) => void;
}

const InputForm: React.FC<InputFormProps> = ({
  class_style: className = "col-span-6 sm:col-span-3",
  label,
  value = "",
  placeholder,
  id,
  name,
  required,
  type = "text",
  readOnly: re = false,
  validationFn,
  onChange,
  ...props
}) => {
  const [error, setError] = useState<string | null | undefined>(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (onChange) onChange(event);
    if (validationFn) setError(validationFn(value));
  };

  let inputValue = value;
  if (type === "date" || type === "datetime-local") {
    inputValue = formatDateWithDefaultTime(value);
  }

  let inputClass =
    "shadow-sm text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 block w-full p-2.5 dark:text-white dark:placeholder-gray-400 ";

  if (error) {
    inputClass +=
      "border-red-500 bg-red-50 placeholder-red-700 text-red-900 focus:ring-red-500 dark:bg-gray-700 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 ";
  } else {
    inputClass +=
      "border border-gray-300 focus:border-primary-500 dark:border-gray-600 ";
  }

  if (re) {
    inputClass += "bg-gray-200 dark:bg-gray-900 ";
  } else if (!error) {
    inputClass += "bg-gray-50 dark:bg-gray-700 ";
  }

  return (
    <div className={className} style={{ position: "relative" }}>
      <label
        htmlFor={name || label}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white select-none"
      >
        {label}
        {/* {required && <span style={{ color: "red" }}> *</span>} */}
      </label>
      <input
        type={type === "password" && showPassword ? "text" : type}
        name={name || label}
        id={id || label}
        className={inputClass}
        value={inputValue}
        onChange={handleChange}
        readOnly={re}
        placeholder={placeholder}
        required={required}
        pattern={
          type === "email"
            ? "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}"
            : undefined
        }
        {...props}
      />
      {error && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          <span className="font-medium">Error:</span> {error}
        </p>
      )}
      {type === "password" && (
        <button
          type="button"
          className="absolute bottom-3 right-2 flex items-center"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-5 w-5 text-gray-700 dark:text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-5 w-5 text-gray-700 dark:text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M2 12s4.58-8 10-8 10 8 10 8-4.58 8-10 8-10-8-10-8z"
              />
            </svg>
          )}
        </button>
      )}
    </div>
  );
};

export default InputForm;
