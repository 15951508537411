// NOTE: This is where you could wire up your own data providers:
// GraphQL, Databases, REST APIs, CDNs, proxies, S3, Matrix, IPFS, you name it…
import { API_URL_CLIENT } from './constants';
// import { getCookiesFromClient } from './utils';

export async function fetchData<ResponseType>(
	endpoint: string,
	method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET',
	headers: HeadersInit = {
		'Content-Type': 'application/json'
	},
	body?: any,
	is_client?: boolean,
	content_file?: boolean,
	useTokens?: boolean
): Promise<ResponseType> {
	// let apiEndpoint = `${API_URL}${endpoint}`;
	let apiEndpoint = `${endpoint}`;
	const body_data = body ? (content_file ? body : JSON.stringify(body)) : null;
	useTokens = useTokens !== undefined ? useTokens : true;
	const options: RequestInit = {
		method,
		headers,
		// credentials: 'include', // NOTE: This is needed for `fetch` to send cookies.
		body: body_data
	};
	//codigo posible a eliminar
	if (is_client) {
		apiEndpoint = `${API_URL_CLIENT}${endpoint}`;
		// apiEndpoint = `${endpoint}`;
		if (useTokens) {
			// const cookies = getCookiesFromClient(["jwt_access", "jwt_refresh"]);
			// if (cookies.jwt_access) {
			options.headers = {
				...options.headers
				// Authorization: `Bearer ${cookies.jwt_access}`,
			};
			// }
		}
	}
	// ===============
	console.info(`Fetching ${apiEndpoint}…`);
	return fetch(apiEndpoint, options)
		.then((response) => {
			if (response.status === 401) {
				// console.log('Unauthorized');
				// return Promise.reject('Unauthorized');
				return response.json().then((err) => {
					throw err;
				});
			}
			if (!response.ok) {
				// return Promise.reject(response.statusText);
				return response.json().then((err) => {
					throw err;
				});
			}
			return response.json() as Promise<ResponseType>;
		})
		.catch((error) =>
			// console.error('ERROR!', error);
			Promise.reject(error)
		);
}
// NOTE: These helpers are useful for unifying paths, app-wide
export function url(path = '') {
	// return `${import.meta.env.SITE}${import.meta.env.BASE_URL}${path}`;
	return `/${path}`;
}
// TODO: Remove old local assets from git history (to make cloning snappier).
// export function asset(path: string) {
//   // NOTE: Fetching remote assets from the Hugo admin dashboard Vercel dist.
//   return `${REMOTE_ASSETS_BASE_URL}/${path}`;
// }
export function redirect(url: string) {
	window.location.href = url;
}
