import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ForgotPassword from "./pages/auth/forgot-password";
import ResetPassword from "./pages/auth/resetPassword";
import Steps from "./modules/Steps/Steps";
import Error404 from "./pages/404";
import { CLIENT_ID } from "./lib/constants";
import { GoogleOAuthProvider } from "@react-oauth/google";

function EnsureTrailingSlash() {
  const location = useLocation();

  useEffect(() => {
    const djangoRoutes = ['/admin', '/other-django-route'];  
    const currentPath = location.pathname;

    if (djangoRoutes.some(route => currentPath.startsWith(route) && !currentPath.endsWith('/'))) {
      window.location.href = currentPath + '/';
    }
  }, [location]);

  return null;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <EnsureTrailingSlash />
      <GoogleOAuthProvider clientId={CLIENT_ID ?? ""}>
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/auth/login" element={<Login />}></Route>
          <Route path="/auth/register" element={<Register />}></Route>
          <Route
            path="/auth/forgot-password"
            element={<ForgotPassword />}
          ></Route>
          <Route
            path="/auth/reset-password/:token"
            element={<ResetPassword />}
          ></Route>
          <Route path="/auth/steps/" element={<Steps />}></Route>
          <Route element={<Error404 />}></Route>
        </Routes>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
