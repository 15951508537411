import { useState } from 'react';

const Button = ({ id, text, selected, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={`px-6 py-5 me-2 mb-2 font-medium text-gray-900 focus:outline-none rounded-lg border-2 border-gray-300 hover:bg-gray-100 hover:text-green-400 hover:border-green-400 focus:z-10 focus:ring-4 focus:ring-gray-200 ${selected ? 'text-green-400 border-green-400' : ''}`}
        >
            {text}
        </div>
    );
};

const Step1 = ({ onNextStep, onDataSubmit }) => {
    const [selectedActivity, setSelectedActivity] = useState(null);

    const onPreviousStep = () => {
        window.location.href = 'register';
    }


    const handleCardClick = (text) => {
        setSelectedActivity(text);
        onDataSubmit(text);
        onNextStep();
    };

    const handleSkip = () => {
        setSelectedActivity(null);
        onDataSubmit(null);
        onNextStep();
    };

    return (
        <div>
            <h5 className="text-xl text-center font-bold text-gray-700 mb-5">
                Elige la actividad a la qué te dedicas y en la que seguirás creciendo
            </h5>
            <div className="w-full">
                <ul className="text-center items-center">
                    <li>
                        <Button
                            id="01"
                            text="Ventas al pro mayor y menor"
                            selected={selectedActivity === 'Ventas al pro mayor y menor'}
                            onClick={() => handleCardClick('Ventas al pro mayor y menor')}
                        />
                    </li>
                    <li>
                        <Button
                            id="02"
                            text="Alimentacion y Gastronomia"
                            selected={selectedActivity === 'Alimentacion y Gastronomia'}
                            onClick={() => handleCardClick('Alimentacion y Gastronomia')}
                        />
                    </li>
                    <li>
                        <Button
                            id="03"
                            text="Cuidado personal / Estética"
                            selected={selectedActivity === 'Cuidado personal / Estética'}
                            onClick={() => handleCardClick('Cuidado personal / Estética')}
                        />
                    </li>
                    <li>
                        <Button
                            id="04"
                            text="Educación"
                            selected={selectedActivity === 'Educación'}
                            onClick={() => handleCardClick('Educación')}
                        />
                    </li>
                    <li>
                        <Button
                            id="05"
                            text="Salud / Farmacia"
                            selected={selectedActivity === 'Salud / Farmacia'}
                            onClick={() => handleCardClick('Salud / Farmacia')}
                        />
                    </li>
                    <li>
                        <Button
                            id="06"
                            text="Transporte & Logistitca"
                            selected={selectedActivity === 'Transporte & Logistitca'}
                            onClick={() => handleCardClick('Transporte & Logistitca')}
                        />
                    </li>
                </ul>
            </div>
            <div className="flex justify-between">
                <button
                    type="button"
                    onClick={onPreviousStep}
                    className="focus:outline-none text-white bg-[#21a89f] hover:bg-[#21a89f] focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-10 py-2.5 me-2 mb-2"
                >
                    Retroceder
                </button>
                <button
                    type="button"
                    onClick={handleSkip}
                    className="focus:outline-none text-white bg-[#21a89f] hover:bg-[#21a89f] focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-10 py-2.5 me-2 mb-2"
                >
                    Omitir
                </button>
            </div>
        </div>
    );
};

export default Step1;