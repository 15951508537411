export const Location = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0.0 0.0 413.7 417.8" zoomAndPan="magnify" fill="rgb(0, 0, 0);" original_string_length="1560">
        <linearGradient xmlnsXlink="http://www.w3.org/1999/xlink" gradientUnits="userSpaceOnUse" id="__id163_soqkej9z19" x1="79.377" x2="334.311" xlinkActuate="onLoad" xlinkShow="other" xlinkType="simple" y1="185.649" y2="185.649">
            <stop offset="0" stopColor="rgb(203, 108, 230);" />
            <stop offset="1" stopColor="rgb(49, 1, 255);" />
        </linearGradient>
        <path d="M261.2,123.4c0,30.2-24.6,54.8-54.8,54.8s-54.8-24.6-54.8-54.8s24.6-54.8,54.8-54.8S261.2,93.2,261.2,123.4z M303.8,202.1c-23.8,35.2-42.4,60.2-61.8,99l-35.1,70.2l-35.1-70.2c-19.4-38.8-38.1-63.8-61.8-99c-15.7-23.2-32.5-52.3-30.4-83.6 C83.9,55.5,134.9,4.6,197.8,0.3c3-0.2,6.1-0.3,9.1-0.3c3,0,6,0.1,9.1,0.3c62.9,4.3,113.9,55.2,118.2,118.2 C336.3,149.8,319.4,178.8,303.8,202.1z M275.2,123.4c0-37.9-30.9-68.8-68.8-68.8s-68.8,30.9-68.8,68.8s30.9,68.8,68.8,68.8 S275.2,161.3,275.2,123.4z" fill="#28a6ae" />
        <linearGradient xmlnsXlink="http://www.w3.org/1999/xlink" gradientUnits="userSpaceOnUse" id="__id164_soqkej9z19" x1="0" x2="413.689" xlinkActuate="onLoad" xlinkShow="other" xlinkType="simple" y1="380.142" y2="380.142">
            <stop offset="0" stopColor="rgb(49, 1, 255);" />
            <stop offset="1" stopColor="rgb(203, 108, 230);" />
        </linearGradient>
        <path d="M413.7,377.6v5.2c0,19.3-15.7,35-35,35H35c-19.3,0-35-15.7-35-35v-5.2c0-19.3,15.7-35,35-35h141.9l17.5,35 l12.5,25l12.5-25l17.5-35h141.9C398,342.6,413.7,358.3,413.7,377.6z" fill="#28a6ae" />
    </svg>
);