import { motion } from "framer-motion";

// import { AmazonLogo } from "../../../public/assets/logos/AmazonLogo";
// import { DropboxLogo } from "../../../public/assets/logos/DropboxLogo";
// import { NetflixLogo } from "../../../public/assets/logos/NetflixLogo";
// import { SlackLogo } from "../../../public/assets/logos/SlackLogo";
// import { SpotifyLogo } from "../../../public/assets/logos/SpotifyLogo";
// import { StripeLogo } from "../../../public/assets/logos/StripeLogo";

export const Brands = () => (
    <section className="py-12 sm:py-24 bg-customDarkBg1 w-full mt-16 mb-20">
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
        >
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                <h5 className="mb-2 text-3xl font-bold text-gray-900">Disponible para descargar en las principales tiendas digitales</h5>
                <p className="mb-5 text-base text-gray-500 sm:text-lg">Manténgase actualizado y avance con Obox en Windows y Android. Descarga la aplicación hoy...</p>
                <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
                    <span href="#" className="w-full sm:w-auto bg-gray-900 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5">
                        <svg className="me-3 w-7 h-7 text-white" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 23 23">
                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                        </svg>
                        <div className="text-left rtl:text-right">
                            <div className="-mt-1 font-sans font-semibold">Descargar para Windows</div>
                        </div>
                    </span>
                    <span href="#" className="w-full sm:w-auto bg-gray-900 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5">
                        <svg className="me-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"></path></svg>
                        <div className="text-left rtl:text-right">
                            <div className="-mt-1 font-sans font-semibold">Descargar para Android</div>
                        </div>
                    </span>
                </div>
            </div>
        </motion.div>
    </section >
);
