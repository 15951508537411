import { useEffect } from 'react';
import FormRegister from './FormRegister';
import { url } from '../../../lib/data';
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { usePricingStore, useRegisterStore } from "../../../store/useStore";

function RegisterAuth() {
    const { freeTrialData, setFreeTrialData } = usePricingStore();
    const { formDataRegister, setFormDataRegister } = useRegisterStore();

    useEffect(() => {
        if (freeTrialData === null) {
            const pymePlan = { type: "pyme", pay: 89, selectPay: '/ mes', users: 3 };
            setFreeTrialData(pymePlan);
        } else {
            setFreeTrialData(freeTrialData);
        }
    }, [freeTrialData, setFreeTrialData]);

    const Authlogin = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                const res = await axios.get(
                    "https://www.googleapis.com/oauth2/v3/userinfo",
                    {
                        headers: {
                            Authorization: `Bearer ${response.access_token}`,
                        },
                    }
                );
                // Función para manejar la lógica de actualización de datos de inicio de sesión
                setFormDataRegister({
                    ...formDataRegister,
                    email: res.data.email,
                    password: "",
                    googleAuth: true,
                });
                window.location.href = '/auth/steps';
            } catch (err) {
                console.log(err);
            }
        }
    })

    return (
        <div className="flex h-screen" id="register">
            {/* Left Pane */}
            <div className="hidden lg:flex items-center justify-center flex-1 bg-gradient-to-br from-[#2ba5b5] to-cyan-300 blur-sm">
            </div>
            <div className="w-full bg-gray-100 lg:w-1/2 flex items-center justify-center">
                <div className="max-w-md w-full p-6">
                    <h1 className="text-3xl font-semibold mb-6 text-black text-center">Obox es gratis por 15 días</h1>
                    <FormRegister formDataRegister={formDataRegister} setFormDataRegister={setFormDataRegister} />
                    <div className="mt-4 flex flex-col lg:flex-row items-center justify-between">
                        <div className="w-full mb-2 lg:mb-0">
                            <button onClick={() => Authlogin()} type="button" className="w-full flex justify-center items-center bg-[#ddfaf8] text-sm text-gray-900 p-4 rounded-full hover:bg-gray-50 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition-colors duration-300">
                                Registrate con Google
                            </button>
                        </div>
                    </div>
                    <div className="mt-4 text-sm text-gray-600 text-center">
                        <p>¿Ya tienes una cuenta? <a href={url("auth/login")} className="text-[#21a89f] hover:underline">Iniciar Sesión</a>
                        </p>
                    </div>
                    <div className="mt-1 text-sm text-gray-600 text-center">
                        <p>Al registrarme, acepto la <span className="text-[#21a89f] hover:underline">Política de Privacidad y los Terminos de servicio de Obox</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterAuth;
