import React from 'react';
import Layout from '../../layouts/Layout';
import LoginAuth from '../../modules/user/SignIn/LoginAuth.jsx';
import '../../styles/Theme.css';
import '../../styles/Diagonals.css';

function Login() {
    return (
        <Layout title="Obox">
            <LoginAuth />
        </Layout>
    );
}

export default Login;

