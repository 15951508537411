import React from 'react';
import ResetPassword from './ResetPasswordForm';

function FormResetPassword({ token, email }) {
    return (
        <div className="flex h-screen">
            {/* Left Pane */}
            <div className="hidden lg:flex items-center justify-center flex-1 bg-gradient-to-br from-[#2ba5b5] to-cyan-300 blur-sm">
            </div>
            <div className="w-full bg-gray-100 lg:w-1/2 flex items-center justify-center">
                <div className="max-w-md w-full p-6">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                        Reestablecer tu contraseña
                    </h2>
                    <div>
                        <ResetPassword token={token} email={email} clientLoad />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormResetPassword;


