import Layout from "./layouts/Layout.jsx";
import { Hero } from "./modules/LandingPage/Hero.jsx";
import { Navbar } from "./modules/LandingPage/Navbar.jsx";
import { Features1 } from "./modules/LandingPage/Features1.jsx";
import { Features2 } from "./modules/LandingPage/Features2.jsx";
// import { FeaturesDiagonal } from "../modules/LandingPage/FeaturesDiagonal.jsx";
import Pricing from "./modules/LandingPage/Pricing.jsx";
// import { FAQ } from "../modules/LandingPage/FAQ.jsx";
import { Brands } from "./modules/LandingPage/Brands.jsx";
// import { Divider } from "../modules/LandingPage/Divider";
import { Footer } from "./modules/LandingPage/Footer.jsx";
import { ScrollUpButton } from "./modules/LandingPage/ScrollUpButton.jsx";
import "./styles/Theme.css";
import "./styles/Diagonals.css";

function App() {
  return (
    <Layout title={"Obox"}>
      <Navbar />
      <Hero />
      <Features1 />
      {/* <Divider /> */}
      <Features2 />
      {/* <FeaturesDiagonal /> */}
      <Pricing />
      <Brands />
      {/* <FAQ /> */}
      <Footer />
      <ScrollUpButton />
    </Layout>
  );
}

export default App;
