import React from 'react';
import Layout from '../layouts/Layout.jsx';
import ErrorNotFound from '../modules/ErrorNotFound.jsx';

function Error404() {
    return (
        <Layout title="Obox">
            <ErrorNotFound />
        </Layout>
    );
}

export default Error404;


