export const Like = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" version="1.0" viewBox="0.0 0.0 424.3 411.3" zoomAndPan="magnify" fill="rgb(0, 0, 0);" original_string_length="2688">
        <linearGradient xmlnsXlink="http://www.w3.org/1999/xlink" gradientUnits="userSpaceOnUse" id="__id218_suvmfqkwxv" x1="41.011" x2="424.291" xlinkActuate="onLoad" xlinkShow="other" xlinkType="simple" y1="196.601" y2="196.601">
            <stop offset="0" stopColor="rgb(203, 108, 230);" />
            <stop offset="1" stopColor="rgb(49, 1, 255);" />
        </linearGradient>
        <path d="M318.343,393.201H154.165V181.47l28.405-35.324c0,0,33.529-44.462,33.529-84.718 c0-28.071,0-34.088,0-35.16c0-10.404,5.864-20.235,15.449-24.235C234.832,0.645,238.186,0,241.453,0 c7.242,0,14.045,3.178,18.711,8.557c22.71,26.429,34.934,59.875,34.934,94.143c0,8.63-0.743,17.267-2.359,25.896l-3.174,17.55h20.23 c-9.205,7.178-15.108,18.372-15.108,30.88c0,10.823,4.415,20.655,11.561,27.752c-11.775,6.774-19.735,19.493-19.735,34.026 c0,11.145,4.702,21.227,12.219,28.324c-11.23,6.855-18.791,19.283-18.791,33.414c0,18.822,13.339,34.598,31.05,38.316 c-4.908,6.523-7.8,14.654-7.8,23.451C303.189,374.869,309.138,386.055,318.343,393.201z M41.011,181.47h99.141v211.546H41.011 V181.47z M86.706,357.99c0,9.354,7.583,16.938,16.938,16.938s16.938-7.584,16.938-16.938s-7.583-16.938-16.938-16.938 S86.706,348.637,86.706,357.99z M392.868,216.553h-70.667c-12.278,0-22.231,9.955-22.231,22.232s9.953,22.23,22.231,22.23h70.667 c12.277,0,22.23-9.953,22.23-22.23S405.145,216.553,392.868,216.553z M386.868,278.307h-70.667c-12.278,0-22.231,9.953-22.231,22.23 s9.953,22.232,22.231,22.232h70.667c12.277,0,22.23-9.955,22.23-22.232S399.145,278.307,386.868,278.307z M391.996,340.686 c-2.1-0.646-4.328-0.996-6.636-0.996h-35.93c-1.429,0-2.825,0.139-4.182,0.395h-4.674c-12.278,0-22.231,9.953-22.231,22.23 s9.953,22.232,22.231,22.232h4.67c1.357,0.256,2.755,0.395,4.186,0.395h35.93c2.311,0,4.542-0.35,6.644-0.998 c9.801-2.32,17.095-11.119,17.095-21.629C409.098,351.803,401.8,343,391.996,340.686z M402.061,157.466h-70.667 c-12.278,0-22.231,9.954-22.231,22.231s9.953,22.231,22.231,22.231h70.667c12.277,0,22.23-9.954,22.23-22.231 S414.338,157.466,402.061,157.466z" fill="#28a6ae" />
        <linearGradient xmlnsXlink="http://www.w3.org/1999/xlink" gradientUnits="userSpaceOnUse" id="__id219_suvmfqkwxv" x1="22.74" x2="22.74" xlinkActuate="onLoad" xlinkShow="other" xlinkType="simple" y1="411.324" y2="163.162">
            <stop offset="0" stopColor="rgb(49, 1, 255);" />
            <stop offset="1" stopColor="rgb(203, 108, 230);" />
        </linearGradient>
        <path d="M45.48,175.162v224.163c0,6.627-5.373,12-12,12H12c-6.627,0-12-5.373-12-12V175.162 c0-6.627,5.373-12,12-12h21.48C40.108,163.162,45.48,168.534,45.48,175.162z" fill="#28a6ae" />
    </svg>
);