import React from 'react';
import Layout from '../../layouts/Layout';
import RegisterAuth from '../../modules/user/SignUp/RegisterAuth';
import { Footer } from '../../modules/LandingPage/Footer.jsx';
import '../../styles/Theme.css';
import '../../styles/Diagonals.css';

function Register() {
    return (
        <Layout title="Obox">
            <RegisterAuth />
            <Footer />
        </Layout>
    );
}

export default Register;