import { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";

interface NotificationProps {
  duration?: number;
  onClose?: (value: null) => void;
  children: ReactNode;
}

const Notification: FC<NotificationProps> = ({
  duration = 4500,
  onClose,
  children,
}) => {
  const [closing, setClosing] = useState<boolean>(false);
  const [width, setWidth] = useState("100%");

  useEffect(() => {
    const interval = 10;
    const step = (100 * interval) / duration;

    const timerId = setInterval(() => {
      setWidth((prevWidth) => {
        const newWidth = parseFloat(prevWidth) - step;
        return newWidth <= 0 ? "0%" : `${newWidth}%`;
      });
    }, interval);

    const closeTimer = setTimeout(() => {
      setClosing(true);
      clearInterval(timerId);

      setTimeout(() => {
        if (onClose) onClose(null);
      }, 500);
    }, duration);

    return () => {
      clearTimeout(closeTimer);
      clearInterval(timerId);
    };
  }, [duration, onClose]);

  return (
    <div
      className={`fixed top-4 left-0 right-0 mx-auto max-w-md mb-4 text-gray-500 bg-white rounded-lg shadow z-50 ${
        closing ? "animate-fadeOutUp" : "animate-fadeInDown"
      }`}
      style={{ margin: "auto" }}
    >
      <div className="flex items-center p-4">
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
          <svg
            className="w-6 h-6 text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10.8 5.5 10.4 3m.4 2.4a5.3 5.3 0 0 1 6 4.3l.4 1.8c.4 2.3 2.4 2.6 2.6 3.7.1.6.2 1.2-.3 1.3L6.8 19c-.5 0-.7-.5-.8-1.1-.2-1.2 1.5-2.1 1.1-4.4l-.3-1.8a5.3 5.3 0 0 1 4-6.2Zm-7 4.4a8 8 0 0 1 2-4.9m2.7 13.7a3.5 3.5 0 0 0 6.7-.8l.1-.5-6.8 1.3Z"
            />
          </svg>
          <span className="sr-only">Check icon</span>
        </div>
        <div className="ml-3 text-sm font-medium">{children}</div>

        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 items-center justify-center"
          onClick={() => setClosing(true)}
        >
          <span className="sr-only">Cerrar</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>

      <div
        className="w-full max-w-md h-1 bg-green-500 z-50 border"
        style={{ width }}
      />
    </div>
  );
};

export default Notification;
