import { useEffect, useState } from "react";
import type { FC } from "react";

interface ErrorNotificationProps {
  duration?: number;
  message: string;
  onClose: (value: null) => void;
}

const ErrorNotification: FC<ErrorNotificationProps> = ({
  duration = 4500,
  message,
  onClose,
}) => {
  const [closing, setClosing] = useState<boolean>(false);
  const [width, setWidth] = useState("100%");

  useEffect(() => {
    const interval = 10;
    const step = (100 * interval) / duration;

    const timerId = setInterval(() => {
      setWidth((prevWidth) => {
        const newWidth = parseFloat(prevWidth) - step;
        return newWidth <= 0 ? "0%" : `${newWidth}%`;
      });
    }, interval);

    const closeTimer = setTimeout(() => {
      setClosing(true);
      clearInterval(timerId);

      setTimeout(() => {
        if (onClose) onClose(null);
      }, 500);
    }, duration);

    return () => {
      clearTimeout(closeTimer);
      clearInterval(timerId);
    };
  }, [duration, onClose]);

  return (
    <div
      className={`fixed top-4 left-0 right-0 mx-auto max-w-md mb-4 text-gray-500 bg-white rounded-lg shadow z-50 ${
        closing ? "animate-fadeOutUp" : "animate-fadeInDown"
      }`}
      style={{ margin: "auto" }}
    >
      <div className="flex items-center p-4">
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
          </svg>
          <span className="sr-only">Error icon</span>
        </div>
        <div className="ml-3 text-sm font-normal text-red-500">{message}</div>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 items-center justify-center"
          onClick={() => setClosing(true)}
        >
          <span className="sr-only">Cerrar</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
      <div
        className="w-full max-w-md h-1 bg-red-700 z-50 border"
        style={{ width }}
      />
    </div>
  );
};

export default ErrorNotification;
