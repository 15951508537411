import React from 'react';
import Layout from '../../layouts/Layout';
import FormForgotPassword from '../../modules/user/resetPassword/FormForgotPassword';
import '../../styles/Theme.css';
import '../../styles/Diagonals.css';

function ForgotPassword() {
    return (
        <Layout title="Obox">
            <FormForgotPassword />
        </Layout>
    );
}

export default ForgotPassword;
