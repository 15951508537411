import React from 'react';
import Layout from '../../layouts/Layout';
import FormResetPassword from '../../modules/user/resetPassword/FormResetPassword';
import '../../styles/Theme.css';
import '../../styles/Diagonals.css';
import { getParamsForUrl } from '../../lib/utils';

function ResetPassword() {
    const params = getParamsForUrl(window.location.href, ['token', 'email']);

    if (!params.token && !params.email) {
        // Si no se proporcionan token y email, redirecciona a /login
        window.location.href = 'auth/login';
        return null; // Devuelve null para evitar el renderizado del componente
    }

    return (
        <Layout title="Obox">
            <FormResetPassword token={params.token} email={params.email} />
        </Layout>
    );
}

export default ResetPassword;

