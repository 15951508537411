import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { url } from "../../lib/data";

const navbarLinks = [
    { label: "Inicio", href: "#home", ariaLabel: "Home" },
    { label: "Caracteristicas", href: "#features", ariaLabel: "Features" },
    { label: "Precios", href: "#pricing", ariaLabel: "Pricing" },
    { label: "Contacto", href: "#feedback", ariaLabel: "Feedback" },
];

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className="w-full h-20 flex flex-col justify-center items-center fixed bg-customDarkBg1 lg:bg-customDarkBgTransparent z-40 lg:backdrop-blur-xl">
            <div className="2xl:w-[1280px] xl:w-10/12 w-11/12 flex justify-between items-center relative">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    exit={{ opacity: 0 }}
                >
                    <a className="navbar-link" href="#home" aria-label="Home">
                        <div className="flex justify-start items-center grow basis-0">
                            <div className="font-['Inter'] font-bold text-4xl">
                                <span className="text-[#21a89f]">O</span>
                                <span className="text-[#555555]">box</span>
                            </div>
                        </div>
                    </a>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    exit={{ opacity: 0 }}
                >
                    <div className="hidden lg:flex h-full pl-12 pb-2">
                        {navbarLinks.map(({ href, label, ariaLabel }) => (
                            <a
                                className="text-gray-900 navbar-link"
                                href={href}
                                aria-label={ariaLabel}
                                key={label}
                            >
                                {label}
                            </a>
                        ))}
                    </div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    exit={{ opacity: 0 }}
                >
                    <div className="grow basis-0 justify-end hidden lg:flex">
                        <a
                            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                            href={url("auth/login")}
                            target="_blank"
                            aria-label="source code" rel="noreferrer"
                        >
                            <span className="pt-px">Ingresar</span>
                        </a>
                        <div style={{ marginLeft: '10px' }}></div>
                        <a
                            className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                            href={url("auth/register")}
                            target="_blank"
                            aria-label="source code" rel="noreferrer"
                        >
                            <span className="pt-px">Empieza Gratis</span>
                        </a>
                    </div>
                </motion.div>
                <div
                    className="lg:hidden flex flex-col px-2 py-3 border-solid border border-gray-600 rounded-md cursor-pointer hover:bg-customDarkBg2"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="w-5 h-0.5 bg-gray-500  mb-1"></div>
                    <div className="w-5 h-0.5 bg-gray-500  mb-1"></div>
                    <div className="w-5 h-0.5 bg-gray-500 "></div>
                </div>
            </div>
            {/* Mobile navbar */}
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        exit={{ opacity: 0 }}
                    >
                        <div
                            className="flex flex-col mt-16 lg:hidden absolute top-4 left-0  bg-customDarkBg1 z-50 w-full items-center gap-10 pb-10 border-y border-solid border-customDarkBg3 pt-10"
                        >
                            {navbarLinks.map(({ label, href, ariaLabel }) => (
                                <a
                                    key={href}
                                    className="navbar-link"
                                    href={href}
                                    onClick={() => setIsOpen(false)}
                                    aria-label={ariaLabel}
                                >
                                    {label}
                                </a>
                            ))}
                            <a
                                className="text-[#555555] text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5"
                                href={url("auth/login")}
                                target="_blank" rel="noreferrer"
                            >
                                Ingresar
                            </a>
                            <a
                                className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                href={url("auth/register")}
                                target="_blank" rel="noreferrer"
                            >
                                Empieza Gratis
                            </a>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </nav>
    );
};
