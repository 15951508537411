import { motion } from "framer-motion";

import { Location } from "../../assets/icons/Location";
import { EditCircle } from "../../assets/icons/EditCircle";
import { Like } from "../../assets/icons/Like";
import { ClapIcon } from "../../assets/icons/ClapIcon";

export const Features1 = () => {
    return (
        <section className="w-full bg-customDarkBg1" id="features">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <div className="mx-auto mb-8 max-w-screen-sm lg:mb-16">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Características</h2>
                    </div>
                    <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <div className="mx-auto mb-4 w-36 h-36 rounded">
                                <Location />
                            </div>
                            {/* <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={location} alt="location" /> */}
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                                Tecnología en la nube
                            </h3>
                            <p>Controla tu mype desde cualquier dispositivo y donde sea que estés</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <div className="mx-auto mb-4 w-36 h-36 rounded">
                                <EditCircle />
                            </div>
                            {/* <img className="mx-auto mb-4 w-36 h-36 rounded-full" src={EditCircle} alt="Edit" /> */}
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                                Soporte ilimitado
                            </h3>
                            <p>Recibes ayuda gratuita de expertos en contabilidad</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <div className="mx-auto mb-4 w-36 h-36 rounded">
                                <Like />
                            </div>
                            {/* <img className="mx-auto mb-4 w-36 h-36 rounded" src={Like} alt="like" /> */}
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                                Siempre al día con la SUNAT
                            </h3>
                            <p>Actualizaciones gratuitas para que cumplas con la SUNAT</p>
                        </div>
                        <div className="text-center text-gray-500 dark:text-gray-400">
                            <div className="mx-auto mb-4 w-36 h-36 rounded">
                                <ClapIcon />
                            </div>
                            {/* <img className="mx-auto mb-4 w-36 h-36 rounded" src={Claps} alt="Claps" /> */}
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900">
                                Actualizaciones gratuitas
                            </h3>
                            <p>Obten muchas características adicionales sin costos adicionales</p>
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    );
};
