import React from 'react';
import { useEffect } from 'react';
import { url } from '../../../lib/data';
import { handleChangeForm } from '../../../lib/utils';
import InputForm from '../../../components/InputForm';
import ErrorNotification from '../../../components/ErrorNotification';
import Notification from '../../../components/Notification';
import { useAuth } from '../../../hooks/useAuth';
import LoadingIndicator from '../../../components/LoadingIndicator';

interface FormSignInProps {
	active_user?: boolean;
	message?: string | null;
}

const FormSignIn: React.FC<FormSignInProps> = ({ active_user, message }) => {
	const { login, setLoginData, loginData, loading, error, setError, notification, setNotification } = useAuth();

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		login();
	};

	useEffect(() => {
		if (message) {
			setNotification(message);
		}
	}, [message, setNotification]);

	return (
		// component
		<div>
			<div>
				{/* <!-- Right Pane --> */}
				<form onSubmit={handleSubmit} className="space-y-4">
					<div>
						<label htmlFor="email" className="block text-sm font-medium text-gray-700">
							Correo Electronico
						</label>
						<InputForm
							type="email"
							name="email"
							value={loginData.email}
							className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
							placeholder="name@company.com"
							id="email"
							required
							onChange={(e) => handleChangeForm(e, setLoginData)}
						/>
					</div>
					<div>
						<div className="flex justify-between">
							<label htmlFor="password" className="block text-sm font-medium text-gray-700">
								Contraseña
							</label>
							<p>
								<a
									href={url('auth/forgot-password')}
									className="text-[#21a89f] text-sm hover:underline"
								>
									¿Olvidaste tu contraseña?
								</a>
							</p>
						</div>
						<InputForm
							type="password"
							name="password"
							value={loginData.password}
							placeholder="••••••••"
							className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
							id="password"
							required
							onChange={(e) => handleChangeForm(e, setLoginData)}
						/>
					</div>
					<div>
						<button
							type="submit"
							className="w-full bg-[#21a89f] text-white p-2 rounded-md hover:bg-gray-800 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors duration-300"
						>
							Ingresar
						</button>
					</div>
				</form>
			</div>
			{error && <ErrorNotification message={error} onClose={setError} />}
			{notification && <Notification onClose={setNotification}>{notification}</Notification>}
			{loading && <LoadingIndicator />}
		</div>
	);
};

export default FormSignIn;
